import config from "../config/env.config";
import {getToken} from "../utils/auth";
import {PaginatedInvoicesResponse} from "../types/invoice.types";
import {InventoryItemsResponse} from "../types/inventory.types";


export const fetchInvoices = async (
    page: number = 1, 
    pageSize: number = 10,
    startDate?: string,
    endDate?: string,
    uploadStatus?: string
) => {
    try {
        const token = await getToken();
        let url = `${config.apiUrl}/invoices?page=${page}&page_size=${pageSize}`;
        
        if (startDate) url += `&start_date=${startDate}`;
        if (endDate) url += `&end_date=${endDate}`;
        if (uploadStatus && uploadStatus !== 'all') url += `&upload_status=${uploadStatus}`;
        
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json() as PaginatedInvoicesResponse;
        return data;
    } catch (error) {
        console.error('Failed to fetch invoices:', error);
        throw error;
    }
};

export const deleteInvoiceById = async (invoiceId: string) => {
    try {
        const token = await getToken();
        await fetch(`${config.apiUrl}/invoices/${invoiceId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error('Failed to delete invoice:', error);
    }
};

export const uploadInvoicesIntuipos = async (invoicesList: any) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/intuipos_upload`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({invoices: invoicesList}),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to upload invoices:', error);
    }
};

export const validateInvoices = async (intuiposInvoices: any) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/validate_invoices`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({invoices: intuiposInvoices}),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to validate invoices:', error);
    }
}

export const uploadInvoicesSiigo = async (invoicesList: any) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/siigo_upload_invoices`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({invoices: invoicesList}),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to upload invoices:', error);
    }
};

export const uploadInvoicesHelisa = async (invoicesList: any) => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/helisa_upload`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({invoices: invoicesList}),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to upload invoices:', error);
    }
}

export const fetchInventoryItems = async () => {
    try {
        const token = await getToken();
        const response = await fetch(`${config.apiUrl}/inventory_items`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json() as InventoryItemsResponse;
    } catch (error) {
        console.error('Failed to fetch inventory items:', error);
    }
};

export const updateInvoice = async ({invoice}: any) => {
    try {
        const token = await getToken();
        console.log(invoice)
        const response = await fetch(`${config.apiUrl}/invoice_items_update`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({invoice: invoice}),
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to update invoice:', error);
    }
};