import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    FormControl,
    Heading,
    Input,
    Text,
    useToast,
    Select,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Icon,
    IconButton,
    Tooltip,
    Image,
    Checkbox,
    Skeleton,
} from "@chakra-ui/react";
import { FaEye, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Invoice, PaginatedInvoicesResponse } from "../types/invoice.types";
import {
    fetchInvoices,
    uploadInvoicesHelisa,
    uploadInvoicesIntuipos,
    uploadInvoicesSiigo,
    validateInvoices,
} from "../services/invoices.services";

export default function Invoices() {
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
    const [filteredInvoices, setFilteredInvoices] = useState<Invoice[]>([]);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalInvoices, setTotalInvoices] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [hasMore, setHasMore] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<string>('all');

    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        loadInvoices();
    }, [currentPage, pageSize, startDate, endDate, uploadStatus]);

    const loadInvoices = async () => {
        setIsLoading(true);
        try {
            const data = await fetchInvoices(
                currentPage, 
                pageSize, 
                startDate || undefined, 
                endDate || undefined,
                uploadStatus
            );
            setInvoices(data.invoices);
            setFilteredInvoices(data.invoices);
            setTotalInvoices(data.total);
            setHasMore(data.has_more);
        } catch (error) {
            console.error("Error fetching invoices:", error);
            toast({
                title: "Error fetching invoices",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value || null;
        setStartDate(date);
        setCurrentPage(1); // Reset to first page when filtering
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value || null;
        setEndDate(date);
        setCurrentPage(1); // Reset to first page when filtering
    };

    const handleSelectInvoice = (invoice: any) => {
        if (selectedInvoices.includes(invoice.id)) {
            let filteredInvoices = selectedInvoices.filter(
                (item) => item !== invoice.id
            );
            setSelectedInvoices(filteredInvoices);
        } else {
            setSelectedInvoices([...selectedInvoices, invoice.id]);
        }
    };

    const handleCopyToClipboard = (text: string, description: string) => {
        navigator.clipboard.writeText(text).then(() => {
            toast({
                title: `${description} copiado al portapapeles`,
                status: 'info',
                duration: 3500,
                isClosable: true,
            });
        });
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
        // Reset selected invoices when changing pages
        setSelectedInvoices([]);
    };

    const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newPageSize = parseInt(e.target.value);
        setPageSize(newPageSize);
        setCurrentPage(1); // Reset to first page when changing page size
        // Reset selected invoices when changing page size
        setSelectedInvoices([]);
    };

    const handleUploadStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newUploadStatus = e.target.value;
        setUploadStatus(newUploadStatus);
        setCurrentPage(1); // Reset to first page when filtering
    };

    return (
        <Box height="100%" p={4}>
            <Flex direction="column" gap={6}>
                {/* Header Section */}
                <Flex 
                    justify="space-between" 
                    align="center"
                    bg="white"
                    p={4}
                    borderRadius="lg"
                    boxShadow="sm"
                    border="1px"
                    borderColor="gray.200"
                >
                    <Heading size="md" fontWeight="500" color="gray.700">
                        Facturas
                    </Heading>

                    <HStack spacing={3}>
                        {/* <Button
                            size="sm"
                            colorScheme="green"
                            variant="solid"
                            leftIcon={<Icon as={FaCheck} boxSize="12px" />}
                            onClick={() => {
                                validateInvoices(selectedInvoices).then((data) => {
                                    let title = data.status === "success" 
                                        ? 'Facturas validadas correctamente'
                                        : 'No se han seleccionado facturas para validar';
                                    
                                    toast({
                                        position: "top-right",
                                        title,
                                        status: data.status,
                                        duration: 12000,
                                        isClosable: true,
                                    });
                                });
                            }}
                        >
                            Validar facturas
                        </Button> */}

                        <Button
                            size="sm"
                            colorScheme="blue"
                            variant="outline"
                            onClick={() => {
                                uploadInvoicesIntuipos(selectedInvoices).then((data) => {
                                    const title = data.status === "success"
                                        ? "Facturas subidas a Intuipos"
                                        : "Error al subir facturas";

                                    const description = data.status === "success"
                                        ? `Facturas subidas correctamente ${data.successfully_uploaded.length} \n Facturas con error ${data.error_uploaded.length}`
                                        : "No se han seleccionado facturas para subir";

                                    toast({
                                        position: "top-right",
                                        title,
                                        description,
                                        status: data.status,
                                        duration: 12000,
                                        isClosable: true,
                                    });
                                });
                            }}
                        >
                            <Flex align="center" gap={2}>
                                <Image src="/intuipos_icon.png" alt="Intuipos" boxSize="16px"/>
                                <Text>Subir a Intuipos</Text>
                            </Flex>
                        </Button>

                        <Button
                            size="sm"
                            colorScheme="blue"
                            variant="outline"
                            onClick={() => {
                                uploadInvoicesSiigo(selectedInvoices).then((data) => {
                                    const description = data.status === "success"
                                        ? `Facturas subidas correctamente ${data.successfully_uploaded.length} \n Facturas con error ${data.error_uploaded.length}`
                                        : "No se han seleccionado facturas para subir";

                                    toast({
                                        position: "top-right",
                                        title: "Facturas subidas a Siigo",
                                        description,
                                        status: data.status,
                                        duration: 12000,
                                        isClosable: true,
                                    });
                                });
                            }}
                        >
                            <Flex align="center" gap={2}>
                                <Image src="/siigo_blue_icon.png" alt="Siigo" boxSize="16px"/>
                                <Text>Subir a Siigo</Text>
                            </Flex>
                        </Button>

                        <Button
                            size="sm"
                            colorScheme="blue"
                            variant="outline"
                            onClick={() => {
                                uploadInvoicesHelisa(selectedInvoices).then((data) => {
                                    if (data.url !== null) {
                                        toast({
                                            position: "bottom",
                                            title: "El archivo plano se ha generado correctamente",
                                            status: 'success',
                                            duration: 12000,
                                            isClosable: true,
                                        });
                                        window.open(data.url, '_blank');
                                    } else {
                                        toast({
                                            position: "top-right",
                                            title: "El archivo plano no se ha generado correctamente",
                                            status: 'error',
                                            duration: 12000,
                                            isClosable: true,
                                        });
                                    }
                                });
                            }}
                        >
                            <Flex align="center" gap={2}>
                                <Image src="/helisa_logo.png" alt="Helisa" width="16px"/>
                                <Text>Subir a Helisa</Text>
                            </Flex>
                        </Button>
                    </HStack>
                </Flex>

                {/* Filters Section */}
                <Box 
                    bg="white" 
                    p={4} 
                    borderRadius="lg"
                    boxShadow="sm"
                    border="1px"
                    borderColor="gray.200"
                >
                    <Flex gap={4}>
                        <FormControl>
                            <Text fontSize="sm" mb={2} color="gray.600">Fecha inicial</Text>
                            <Input
                                type="date"
                                bg="white"
                                color="gray.800"
                                size="md"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.400" }}
                                _focus={{ borderColor: "blue.400", boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)" }}
                                value={startDate || ''}
                                onChange={handleStartDateChange}
                            />
                        </FormControl>
                        <FormControl>
                            <Text fontSize="sm" mb={2} color="gray.600">Fecha final</Text>
                            <Input
                                type="date"
                                bg="white"
                                color="gray.800"
                                size="md"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.400" }}
                                _focus={{ borderColor: "blue.400", boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)" }}
                                value={endDate || ''}
                                onChange={handleEndDateChange}
                            />
                        </FormControl>
                        <FormControl>
                            <Text fontSize="sm" mb={2} color="gray.600">Estado de carga</Text>
                            <Select 
                                bg="white"
                                color="gray.800"
                                size="md"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.400" }}
                                _focus={{ borderColor: "blue.400", boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)" }}
                                onChange={handleUploadStatusChange}
                                value={uploadStatus}
                            >
                                <option value="all">Todas</option>
                                <option value="uploaded">Cargadas</option>
                                <option value="not-uploaded">No cargadas</option>
                            </Select>
                        </FormControl>
                    </Flex>
                </Box>

                {/* Table Section */}
                <Box 
                    bg="white" 
                    borderRadius="lg"
                    boxShadow="sm"
                    border="1px"
                    borderColor="gray.200"
                    overflowX="auto"
                >
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th width="40px" px={2}>
                                    <Checkbox
                                        size="sm"
                                        isDisabled={isLoading}
                                        isChecked={selectedInvoices.length > 0 && selectedInvoices.length === filteredInvoices.length}
                                        isIndeterminate={selectedInvoices.length > 0 && selectedInvoices.length < filteredInvoices.length}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedInvoices(filteredInvoices.map(inv => inv.id));
                                            } else {
                                                setSelectedInvoices([]);
                                            }
                                        }}
                                    />
                                </Th>
                                <Th>ID Factura</Th>
                                <Th>Fecha</Th>
                                <Th>Proveedor</Th>
                                <Th>Validaciones</Th>
                                <Th>Estados de carga</Th>
                                <Th width="60px">Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {isLoading ? (
                                // Loading skeleton rows
                                [...Array(5)].map((_, index) => (
                                    <Tr key={`skeleton-${index}`}>
                                        <Td px={2}><Skeleton height="20px" width="20px" /></Td>
                                        <Td><Skeleton height="20px" width="100px" /></Td>
                                        <Td><Skeleton height="20px" width="80px" /></Td>
                                        <Td><Skeleton height="20px" width="150px" /></Td>
                                        <Td>
                                            <HStack spacing={2}>
                                                <Skeleton height="16px" width="16px" />
                                                <Skeleton height="16px" width="16px" />
                                            </HStack>
                                        </Td>
                                        <Td>
                                            <HStack spacing={2}>
                                                <Skeleton height="16px" width="16px" />
                                                <Skeleton height="16px" width="16px" />
                                                <Skeleton height="16px" width="16px" />
                                            </HStack>
                                        </Td>
                                        <Td><Skeleton height="24px" width="24px" borderRadius="sm" /></Td>
                                    </Tr>
                                ))
                            ) : (
                                // Existing table rows code
                                filteredInvoices.map((invoice) => (
                                    <Tr key={invoice.id}>
                                        <Td px={2}>
                                            <Checkbox
                                                size="sm"
                                                isChecked={selectedInvoices.includes(invoice.id)}
                                                onChange={() => handleSelectInvoice(invoice)}
                                            />
                                        </Td>
                                        <Td>{invoice.invoice_id}</Td>
                                        <Td>{new Date(invoice.issue_date).toLocaleDateString()}</Td>
                                        <Td>{invoice.sender_party_name}</Td>
                                        <Td>
                                            <HStack spacing={2}>
                                                <Tooltip label="Orden de compra">
                                                    <Box opacity={invoice.validations?.purchase_order ? "100%" : "30%"}>
                                                        <Image 
                                                            src="/intuipos_icon.png" 
                                                            alt="Intuipos" 
                                                            boxSize="16px"
                                                        />
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip label="DIAN">
                                                    <Box opacity={invoice.validations?.dian ? "100%" : "30%"}>
                                                        <Image 
                                                            src="/dian_icon.png" 
                                                            alt="DIAN" 
                                                            boxSize="16px"
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            </HStack>
                                        </Td>
                                        <Td>
                                            <HStack spacing={2}>
                                                {invoice.uploads?.intuipos?.uploaded && (
                                                    <Tooltip label={`OrderId: ${invoice.uploads.intuipos.order_id}`}>
                                                        <Box cursor="pointer" onClick={() => handleCopyToClipboard(
                                                            invoice.uploads?.intuipos?.order_id || '',
                                                            'OrderId'
                                                        )}>
                                                            <Image 
                                                                src="/intuipos_icon.png" 
                                                                alt="Intuipos" 
                                                                boxSize="16px"
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                                {invoice.uploads?.siigo?.uploaded && (
                                                    <Tooltip label={`Consecutivo: ${invoice.uploads.siigo.consecutive}`}>
                                                        <Box cursor="pointer" onClick={() => handleCopyToClipboard(
                                                            invoice.uploads?.siigo?.consecutive || '',
                                                            'Consecutivo'
                                                        )}>
                                                            <Image 
                                                                src="/siigo_blue_icon.png" 
                                                                alt="Siigo" 
                                                                boxSize="16px"
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                                {invoice.uploads?.helisa?.uploaded && (
                                                    <Tooltip label="Click para copiar URL">
                                                        <Box cursor="pointer" onClick={() => handleCopyToClipboard(
                                                            invoice.uploads?.helisa?.url || '',
                                                            'URL'
                                                        )}>
                                                            <Image 
                                                                src="/helisa_logo.png" 
                                                                alt="Helisa" 
                                                                boxSize="16px"
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                            </HStack>
                                        </Td>
                                        <Td>
                                            <IconButton
                                                aria-label="Ver productos"
                                                icon={<FaEye />}
                                                size="xs"
                                                onClick={() => {
                                                    localStorage.setItem('selectedInvoice', JSON.stringify(invoice));
                                                    navigate(`/invoice/${invoice.id}`);
                                                }}
                                            />
                                        </Td>
                                    </Tr>
                                ))
                            )}
                        </Tbody>
                    </Table>
                </Box>

                {/* Pagination Section */}
                <Flex justify="space-between" align="center" mt={4}>
                    <HStack>
                        <Text>Facturas por página:</Text>
                        <Select value={pageSize} onChange={handlePageSizeChange} w="70px">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </Select>
                    </HStack>
                    <HStack>
                        <Text>{`${(currentPage - 1) * pageSize + 1}-${Math.min(currentPage * pageSize, totalInvoices)} de ${totalInvoices}`}</Text>
                        <IconButton
                            icon={<FaChevronLeft />}
                            aria-label="Previous page"
                            isDisabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        />
                        <IconButton
                            icon={<FaChevronRight />}
                            aria-label="Next page"
                            isDisabled={!hasMore}
                            onClick={() => handlePageChange(currentPage + 1)}
                        />
                    </HStack>
                </Flex>
            </Flex>
        </Box>
    );
}
