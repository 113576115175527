import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    VStack,
    useToast,
    Grid,
    GridItem,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Invoice, PurchaseOrderValidation } from "../types/invoice.types";
import { updateInvoice } from "../services/invoices.services";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { fetchInventoryItems } from "../services/invoices.services";
import { ProductsTable } from "../components/invoicing/ProductsTable";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

export default function InvoiceDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [loading, setLoading] = useState(true);
    const [inventoryItems, setInventoryItems] = useState<any[]>([]);
    const [itemsOptions, setItemsOptions] = useState<any[]>([]);
    const [isPOExpanded, setIsPOExpanded] = useState(false);

    React.useEffect(() => {
        // Get invoice from localStorage (passed from the main screen)
        const invoiceData = localStorage.getItem('selectedInvoice');
        if (invoiceData) {
            const parsedInvoice = JSON.parse(invoiceData);
            
            // Add mock purchase order validation data
            const mockPurchaseOrder: PurchaseOrderValidation = {
                purchase_order_id: "942941",
                selling_point: "66001 BODEGA LEGRAND 116",
                selling_point_id: 66001,
                creation_date: "2024-11-01",
                due_date: "2024-11-02",
                observations: "No se han encontrado observaciones",
                supplier_name: parsedInvoice.sender_party_name,
                supplier_id: 901168067,
                items: [
                    {
                        description: "TOMATE CHONTO",
                        quantity: 10,
                        measurement_unit: "kg",
                        total: 28000
                    },
                    {
                        description: "AGUACATE HASS",
                        quantity: 9,
                        measurement_unit: "kg",
                        total: 81000
                    },
                    {
                        description: "AGUACATE PAPELILLO",
                        quantity: 9,
                        measurement_unit: "kg",
                        total: 108000
                    }
                ]
            };

            // Add the mock purchase order to the invoice validations
            setInvoice({
                ...parsedInvoice,
                validations: {
                    ...parsedInvoice.validations,
                    purchase_order: mockPurchaseOrder
                }
            });
        }
        setLoading(false);
    }, [id]);

    React.useEffect(() => {
        fetchInventoryItems().then((data) => {
            if (data) {
                setInventoryItems(data.inventory_items);
                const items = data.inventory_items.map((item: any) => ({
                    label: item.item_name,
                    value: item.item_name
                }));
                setItemsOptions(items);
            }
        });
    }, []);

    // const optionsMeasurement = [
    //     { label: "un", value: "un" },
    //     { label: "kg", value: "kg" },
    //     { label: "gr", value: "gr" },
    //     { label: "ml", value: "ml" },
    //     { label: "porción", value: "porción" },
    //     { label: "botella 750 c.c", value: "botella 750 c.c" },
    //     { label: "botella 375 c.c", value: "botella 375 c.c" },
    //     { label: "botella 1000 c.c", value: "botella 1000 c.c" },
    //     { label: "botella 500 c.c", value: "botella 500 c.c" },
    //     { label: "TABLETAS", value: "TABLETAS" },
    //     { label: "BT 700ML", value: "BT 700ML" },
    //     { label: "UND X 750", value: "UND X 750" }
    // ];

    const optionsConversionMeasurements = [
        { label: "un", value: "un" },
        { label: "kg", value: "kg" },
        { label: "lb", value: "lb" },
        { label: "gr", value: "gr" },
        { label: "mg", value: "mg" },
        { label: "ml", value: "ml" },
        { label: "l", value: "l" },
        { label: "oz", value: "oz" },
    ];

    const handleItemChange = (selectedOption: any, index: number) => {
        const item = inventoryItems.find((item: any) => item.item_name === selectedOption.value);
        if (item && invoice) {
            const updatedItems = [...invoice.items];
            updatedItems[index] = {
                ...updatedItems[index],
                unit: item.item_unit,
                inventory_item_name: item.item_name,
                inventory_id: item.id,
                item_role_id: item.item_role_id,
                item_role_name: item.item_role_name
            };
            setInvoice({ ...invoice, items: updatedItems });
        }
    };

    const handleTaxChange = (selectedOption: any, index: number, taxIndex: number) => {
        if (!invoice) return;
        const updatedItems = [...invoice.items];
        const taxName = selectedOption.value;
        const updatedTaxes = { ...updatedItems[index].taxes };
        const oldTaxName = Object.keys(updatedTaxes)[taxIndex];
        const oldTaxAmount = updatedTaxes[oldTaxName]?.tax_amount || 0;
        delete updatedTaxes[oldTaxName];
        updatedTaxes[taxName] = { tax_amount: oldTaxAmount };
        updatedItems[index] = {
            ...updatedItems[index],
            taxes: updatedTaxes,
        };
        setInvoice({ ...invoice, items: updatedItems });
    };

    const handleTaxAmountChange = (value: any, index: number, taxName: string) => {
        if (!invoice) return;
        const updatedItems = [...invoice.items];
        const updatedTaxes = { ...updatedItems[index].taxes };
        updatedTaxes[taxName] = { tax_amount: Number(value) };
        updatedItems[index] = {
            ...updatedItems[index],
            taxes: updatedTaxes,
        };
        setInvoice({ ...invoice, items: updatedItems });
    };

    const optionsTaxes = [
        { label: "IVA 0%", value: "IVA 0%" },
        { label: "IVA 16%", value: "IVA 16%" },
        { label: "IVA 5%", value: "IVA 5%" },
        { label: "INC (COMPRAS)", value: "INC (COMPRAS)" },
        { label: "IVA (VAR)%", value: "IVA (VAR)%" },
        { label: "IVA 19%", value: "IVA 19%" },
        { label: "Bolsas", value: "Bolsas" },
        { label: "IC Porcentual", value: "IC Porcentual" },
        { label: "INC 9%", value: "INC 9%" },
        { label: "IBUA < 6gr/100 ml", value: "IBUA < 6gr/100 ml" },
        { label: "IBUA >= 6gr < 10gr / 100 ml", value: "IBUA >= 6gr < 10gr / 100 ml" },
        { label: "IBUA >= 10gr / 100 ml", value: "IBUA >= 10gr / 100 ml" },
        { label: "ICUI", value: "ICUI" },
        { label: "I.G.V 18", value: "I.G.V 18" }
    ];

    const renderPurchaseOrderSection = (validation: PurchaseOrderValidation) => (
        <Box
            bg="white"
            p={4}
            borderRadius="lg"
            boxShadow="sm"
            border="1px"
            borderColor="gray.200"
        >
            <VStack align="stretch" spacing={4}>
                <Flex 
                    justify="space-between" 
                    align="center" 
                    cursor="pointer"
                    onClick={() => setIsPOExpanded(!isPOExpanded)}
                    _hover={{ bg: 'gray.50' }}
                    p={2}
                    borderRadius="md"
                >
                    <Heading size="sm">Orden de Compra</Heading>
                    {isPOExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Flex>
                
                <Box display={isPOExpanded ? "block" : "none"}>
                    {/* Purchase Order Details Grid */}
                    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                        <Box>
                            <Text fontWeight="bold">ID Orden</Text>
                            <Text>{validation.purchase_order_id}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight="bold">Punto de Venta</Text>
                            <Text>{validation.selling_point}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight="bold">Proveedor</Text>
                            <Text>{validation.supplier_name}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight="bold">Fecha de Creación</Text>
                            <Text>{new Date(validation.creation_date).toLocaleDateString()}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight="bold">Fecha de Vencimiento</Text>
                            <Text>{new Date(validation.due_date).toLocaleDateString()}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight="bold">NIT Proveedor</Text>
                            <Text>{validation.supplier_id}</Text>
                        </Box>
                    </Grid>

                    {/* Observations if they exist */}
                    {validation.observations && (
                        <Box mt={4}>
                            <Text fontWeight="bold">Observaciones</Text>
                            <Text>{validation.observations}</Text>
                        </Box>
                    )}

                    {/* Purchase Order Items Table */}
                    <Box mt={4}>
                        <Text fontWeight="bold" mb={2}>Items de la Orden</Text>
                        <Box overflowX="auto">
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr style={{ backgroundColor: 'var(--chakra-colors-gray-50)' }}>
                                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid var(--chakra-colors-gray-200)' }}>Descripción</th>
                                        <th style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid var(--chakra-colors-gray-200)' }}>Cantidad</th>
                                        <th style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid var(--chakra-colors-gray-200)' }}>Unidad</th>
                                        <th style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid var(--chakra-colors-gray-200)' }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {validation.items.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: '8px', borderBottom: '1px solid var(--chakra-colors-gray-100)' }}>{item.description}</td>
                                            <td style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid var(--chakra-colors-gray-100)' }}>{item.quantity}</td>
                                            <td style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid var(--chakra-colors-gray-100)' }}>{item.measurement_unit}</td>
                                            <td style={{ padding: '8px', textAlign: 'right', borderBottom: '1px solid var(--chakra-colors-gray-100)' }}>${item.total.toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                </Box>
            </VStack>
        </Box>
    );

    if (loading || !invoice) {
        return <Box p={4}>Loading...</Box>;
    }

    return (
        <Box p={4}>
            <Flex direction="column" gap={6}>
                {/* Header */}
                <Flex
                    justify="space-between"
                    align="center"
                    bg="white"
                    p={4}
                    borderRadius="lg"
                    boxShadow="sm"
                    border="1px"
                    borderColor="gray.200"
                >
                    <Button
                        leftIcon={<ArrowBackIcon />}
                        variant="ghost"
                        onClick={() => navigate('/')}
                    >
                        Volver
                    </Button>
                    <Heading size="md">Factura {invoice.invoice_id}</Heading>
                    <Button
                        colorScheme="blue"
                        onClick={() => {
                            updateInvoice({ invoice }).then((data) => {
                                toast({
                                    title: "Factura actualizada",
                                    status: data.status,
                                    duration: 3000,
                                    isClosable: true,
                                });
                            });
                        }}
                    >
                        Guardar cambios
                    </Button>
                </Flex>

                {/* Content Grid */}
                <Grid templateColumns="2fr 1fr" gap={6}>
                    {/* Left Column - Invoice Details & Products */}
                    <GridItem>
                        <VStack spacing={6} align="stretch">
                            {/* Invoice Details */}
                            <Box
                                bg="white"
                                p={4}
                                borderRadius="lg"
                                boxShadow="sm"
                                border="1px"
                                borderColor="gray.200"
                            >
                                <VStack align="stretch" spacing={4}>
                                    <Heading size="sm">Detalles de la factura</Heading>
                                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                        <Box>
                                            <Text fontWeight="bold">Proveedor</Text>
                                            <Text>{invoice.sender_party_name}</Text>
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold">NIT</Text>
                                            <Text>{invoice.sender_party}</Text>
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold">Fecha</Text>
                                            <Text>{new Date(invoice.issue_date).toLocaleDateString()}</Text>
                                        </Box>
                                        <Box>
                                            <Text fontWeight="bold">CUFE</Text>
                                            <Text fontSize="sm" noOfLines={1}>{invoice.invoice_cufe}</Text>
                                        </Box>
                                    </Grid>
                                </VStack>
                            </Box>

                            {/* Products Table */}
                            <ProductsTable
                                invoice={invoice}
                                itemsOptions={itemsOptions}
                                optionsConversionMeasurements={optionsConversionMeasurements}
                                optionsTaxes={optionsTaxes}
                                handleItemChange={handleItemChange}
                                handleTaxChange={handleTaxChange}
                                handleTaxAmountChange={handleTaxAmountChange}
                                setInvoice={setInvoice}
                            />

                            {/* Purchase Order Section */}
                            {invoice.validations?.purchase_order && typeof invoice.validations.purchase_order === 'object' && 
                                renderPurchaseOrderSection(invoice.validations.purchase_order as PurchaseOrderValidation)
                            }
                        </VStack>
                    </GridItem>

                    {/* Right Column - PDF Viewer */}
                    <GridItem>
                        <Box
                            bg="white"
                            borderRadius="lg"
                            boxShadow="sm"
                            border="1px"
                            borderColor="gray.200"
                            height="calc(100vh - 200px)"
                            position="sticky"
                            top="20px"
                        >
                            <iframe
                                src={invoice.pdf_url}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: 'none',
                                    borderRadius: '8px'
                                }}
                                title="PDF Viewer"
                            />
                        </Box>
                    </GridItem>
                </Grid>
            </Flex>
        </Box>
    );
} 