import {
    Box,
    Text,
    VStack,
    SimpleGrid,
    Input,
    InputGroup,
    InputRightAddon,
    IconButton,
    Heading,
    Flex,
    Collapse,
    Select,
    HStack,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Select as ChakraSelect } from "chakra-react-select";
import React, { useState } from "react";
import { Invoice } from "../../types/invoice.types";

interface ProductsTableProps {
    invoice: Invoice;
    itemsOptions: any[];
    optionsConversionMeasurements: any[];
    optionsTaxes: any[];
    handleItemChange: (selectedOption: any, index: number) => void;
    handleTaxChange: (selectedOption: any, index: number, taxIndex: number) => void;
    handleTaxAmountChange: (value: any, index: number, taxName: string) => void;
    setInvoice: (invoice: Invoice) => void;
}

export const ProductsTable: React.FC<ProductsTableProps> = ({
    invoice,
    itemsOptions,
    optionsConversionMeasurements,
    optionsTaxes,
    handleItemChange,
    handleTaxChange,
    handleTaxAmountChange,
    setInvoice,
}) => {
    const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});

    const toggleItem = (index: number) => {
        setOpenItems(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    return (
        <Box
            bg="white"
            p={4}
            borderRadius="lg"
            boxShadow="sm"
            border="1px"
            borderColor="gray.200"
        >
            <Heading size="sm" mb={4}>Productos</Heading>
            <VStack align="stretch" spacing={4}>
                {invoice?.items.map((item, index) => {
                    const taxes = Object.keys(item.taxes);
                    const isItemOpen = openItems[index];

                    return (
                        <Box key={index} p={4} borderWidth="1px" borderRadius="md">
                            <Flex justify="space-between" align="center" mb={isItemOpen ? 4 : 0}>
                                <Text fontSize="larger" fontWeight="bold">
                                    {item.description}
                                </Text>
                                <IconButton
                                    aria-label="Toggle item details"
                                    icon={isItemOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                    onClick={() => toggleItem(index)}
                                    variant="ghost"
                                    size="sm"
                                />
                            </Flex>
                            <Collapse in={isItemOpen} animateOpacity>
                                <SimpleGrid columns={2} spacing={4} mt={4}>
                                    <Text fontWeight="bold">Descripción:</Text>
                                    <Box>
                                        <ChakraSelect
                                            defaultValue={{
                                                label: item.inventory_item_name,
                                                value: item.inventory_item_name
                                            }}
                                            options={itemsOptions}
                                            onChange={(selectedOption) => handleItemChange(selectedOption, index)}
                                        />
                                    </Box>

                                    <Text fontWeight="bold">Cantidad:</Text>
                                    <Input
                                        value={item.quantity}
                                        type="number"
                                        onChange={(e) => {
                                            const newItems = [...invoice.items];
                                            newItems[index] = {
                                                ...item,
                                                quantity: Number(e.target.value)
                                            };
                                            setInvoice({ ...invoice, items: newItems });
                                        }}
                                    />

                                    <Text fontWeight="bold">Precio unitario:</Text>
                                    <InputGroup>
                                        <Input
                                            value={item.price_per_unit}
                                            type="number"
                                            onChange={(e) => {
                                                const newItems = [...invoice.items];
                                                newItems[index] = {
                                                    ...item,
                                                    price_per_unit: Number(e.target.value)
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                        />
                                        <InputRightAddon>COP</InputRightAddon>
                                    </InputGroup>

                                    <Text fontWeight="bold">Items por unidad:</Text>
                                    <Input
                                        value={item.conversion?.units || 1}
                                        type="number"
                                        onChange={(e) => {
                                            const newItems = [...invoice.items];
                                            newItems[index] = {
                                                ...item,
                                                conversion: {
                                                    units: Number(e.target.value),
                                                    measurement: item.conversion?.measurement || 'un',
                                                    units_measurement: item.conversion?.units_measurement || 0
                                                }
                                            };
                                            setInvoice({ ...invoice, items: newItems });
                                        }}
                                    />

                                    <Text fontWeight="bold">Cada item tiene:</Text>
                                    <HStack>
                                        <Input
                                            value={item.conversion?.units_measurement || 0}
                                            type="number"
                                            onChange={(e) => {
                                                const newItems = [...invoice.items];
                                                newItems[index] = {
                                                    ...item,
                                                    conversion: {
                                                        units: item.conversion?.units || 1,
                                                        measurement: item.conversion?.measurement || 'un',
                                                        units_measurement: Number(e.target.value)
                                                    }
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                        />
                                        <Select
                                            value={item.conversion?.measurement || 'un'}
                                            onChange={(e) => {
                                                const newItems = [...invoice.items];
                                                newItems[index] = {
                                                    ...item,
                                                    conversion: {
                                                        units: item.conversion?.units || 1,
                                                        measurement: e.target.value,
                                                        units_measurement: item.conversion?.units_measurement || 0
                                                    }
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                        >
                                            {optionsConversionMeasurements.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    </HStack>

                                    <Text fontWeight="bold">Total:</Text>
                                    <InputGroup>
                                        <Input
                                            value={item.price}
                                            type="number"
                                            onChange={(e) => {
                                                const newItems = [...invoice.items];
                                                newItems[index] = {
                                                    ...item,
                                                    price: Number(e.target.value)
                                                };
                                                setInvoice({ ...invoice, items: newItems });
                                            }}
                                        />
                                        <InputRightAddon>COP</InputRightAddon>
                                    </InputGroup>

                                    <Text fontWeight="bold">Impuesto 1:</Text>
                                    <VStack align="stretch">
                                        <ChakraSelect
                                            defaultValue={{label: taxes[0], value: taxes[0]}}
                                            options={optionsTaxes}
                                            onChange={(selectedOption) => handleTaxChange(selectedOption, index, 0)}
                                        />
                                        <InputGroup>
                                            <Input
                                                value={taxes.length > 0 ? item.taxes[taxes[0]]?.tax_amount : ''}
                                                onChange={(e) => handleTaxAmountChange(e.target.value, index, taxes[0])}
                                            />
                                            <InputRightAddon>COP</InputRightAddon>
                                        </InputGroup>
                                    </VStack>

                                    <Text fontWeight="bold">Impuesto 2:</Text>
                                    <VStack align="stretch">
                                        <ChakraSelect
                                            defaultValue={{label: taxes[1], value: taxes[1]}}
                                            options={optionsTaxes}
                                            onChange={(selectedOption) => handleTaxChange(selectedOption, index, 1)}
                                        />
                                        <InputGroup>
                                            <Input
                                                value={taxes.length > 1 ? item.taxes[taxes[1]]?.tax_amount : ''}
                                                onChange={(e) => handleTaxAmountChange(e.target.value, index, taxes[1])}
                                            />
                                            <InputRightAddon>COP</InputRightAddon>
                                        </InputGroup>
                                    </VStack>
                                </SimpleGrid>
                            </Collapse>
                        </Box>
                    );
                })}
            </VStack>
        </Box>
    );
}; 